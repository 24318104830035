@use "./typography";

$font-stack: "Amiko";
$font-stackB: "Lato";

$primaryBlack: #323232;
$primarySilver: #afafaf;
$primaryWhite: #ffffff;

$secondaryDarkBlack: #000000;
$secondaryoffWhite: #f8f5f2;
$secondaryMercury: #e1e1e1;
$secondaryGrey: #d9d5ce;

$tabletBreakpoint: 768px;
$desktopBreakpoint: 1280px;

$maxContentWidth: 1020px;

$shadow: 0.5rem 0.4rem 0.4rem $secondaryMercury;
