@use "../../styles/partials/global";
@use "../../styles/partials/resets";
@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/typography";
@use "../../styles/partials/variables" as *;

.aboutPage {
  &__body {
    max-width: 1080px;
    min-height: 85vh;
    margin: 0 auto;
    padding: 4rem 2rem;
  }
  &__title {
    @include tablet {
      padding-bottom: 4rem;
    }
  }
  &__paragraph1 {
    padding: 2rem 0;
    @include tablet {
      text-align: left;
      padding: 2rem;
    }
  }

  &__layout {
    display: flex;
    flex-direction: column;
    align-items: center;

    @include tablet {
      flex-direction: row;
    }
  }
  &__text-contain {
    
  }
  &__profileImg {
    max-width: 30rem;
  }
}
