@use "../../styles/partials/global";
@use "../../styles/partials/resets";
@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/typography";
@use "../../styles/partials/variables" as *;


.footer {
    &__section {
        background-color: $secondaryoffWhite;
        padding: 4rem;
        height: 18rem; 
        

    }
    &__container {
        max-width: 980px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
    }
    &__block1 {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap:1rem;
    }
    &__icon-block{
        display: flex;   
        align-items: center; 
    }
    &__icon{
        margin-right:1rem;
    }
   &__link{
    text-decoration: none;
    color: black;
   }
    &__github-icon {
        margin-left: .2rem;
    }
    &__block2 { 
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        
        gap: 6rem;
    }
    &__contact--wrapper {
        display: flex;

    }
//    &__email {
//     font-size: 1.6rem;
//     margin-left: .5rem;
//    }
    &__email--link {
        color: black;
        text-decoration: none;
    }
    &__email-icon {
        width: 2.4rem;
        margin-left:.5rem;

    }
    &__copy {
        font-size: 1.4rem;
    }
}