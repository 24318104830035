@use "../../styles/partials/global";
@use "../../styles/partials/resets";
@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/typography";
@use "../../styles/partials/variables" as *;


.contact {
    &__section{
        width: 100%;
        padding: 0 1rem 0 1rem;

        @include tablet {
            height: 80vh;
        }
        
    }
    &__title {
        padding: 2rem 2rem 0 2rem;

        @include tablet {
            padding: 8rem 0 0 0;
        }
    }
    &__form {
        padding:2rem;
        margin: 4rem auto;
        height: auto;
        max-width: 60rem;
        display:flex;
        flex-direction: column;
        border: 1px solid $secondaryMercury;
        border-radius: 10px;
        background-color: $secondaryoffWhite;
    }
    &__subject--wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: .25rem;
        
    }
    &__field {
        border-radius:8px ;
        border: 1px solid $secondaryMercury;
        padding: 1rem;
    }
    &__subject-field {
        width: 100%;
    }
    &__name--wrapper {
        @extend .contact__subject--wrapper;
    }
    &__name-field {
        width:100%;
    }
    &__email--wrapper {
        @extend .contact__subject--wrapper;
    }
    &__email-field {
        width: 100%;
    }
    &__message--wrapper {
        @extend .contact__subject--wrapper;
    }
    &__message-field {
        width: 100%;
        height: 20rem;
        margin-bottom: 2rem;
        font-family: $font-stackB;
    }
    &__button {
        height: 2.4rem;
        border-radius: 6px;
    }
}