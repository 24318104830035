@use "./variables" as *;

button {
  height: 3.6rem;
  font-size: 1.4rem;
  font-weight: 400;
  margin: 0;
  cursor: pointer;

  color: black;
  border: 1px solid black;
  border-radius: 5px;
  box-shadow: 0.5rem 0.4rem 0.4rem $secondaryMercury;
}
