@use "../../styles/partials/global";
@use "../../styles/partials/resets";
@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/typography";
@use "../../styles/partials/variables" as *;

.video {
    &__contain{
        position: relative;
        padding-bottom: 56.25%;
        padding-top: 25;
        height: 0;
        margin: 2rem 1rem;

        @include tablet {
            margin: 4rem 2rem;
        }
    }
    &__iframe{
        position: absolute;
        top:0;
        left:0;
        width:100%;
        height:100%;
        
    }

}