@use "../../styles/partials/global";
@use "../../styles/partials/resets";
@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/typography";
@use "../../styles/partials/variables" as *;

.melder {
  &__main {
    max-width: 1080px;
    // height: 100vh;
    margin: 0 auto;
  }
  &__hero-container {
    width: 100%;
    height: auto;
    margin-top: 2rem;
  }
  &__title {
    font-size: 4rem;
    font-family: $font-stackB;
    margin:2rem 0 ;
  }
  &__hero--responsive {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @include tablet {
      flex-direction: row-reverse;
      
    }
  }
  &__heroImage {
    width: 90%;
    margin-bottom: 2rem;

    @include tablet {
      width: 50rem;
      margin-left: 2rem;
    }
  }
  &__hero-aside {
    font-family: $font-stackB;
    width: 90%;
    @include tablet {
      width: 30rem;
    }
  }
  &__gitLink-layout {
    display: flex;
    flex-direction: column;
  }
  &__gitLink1 {
    padding-top: 1rem;
    text-decoration: none;
  }
  &__gitLink2{
    text-decoration: none;
  }
  &__overview {
    margin: 2rem;
    border-top: 1px solid $secondaryGrey
  }
  &__paragraph {
    font-family: $font-stack;
    font-weight: 400;
    text-align: left;
  }
  &__subtitle {
      margin:2rem 0;
      text-decoration: underline;
      font-size: 2rem;
  }
  &__subtitles {
    font-family: $font-stack;
    font-weight: 600;
    font-size: 1.65rem;
    
  }
}
