@use "../../styles/partials/global";
@use "../../styles/partials/resets";
@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/typography";
@use "../../styles/partials/variables" as *;

.pdf__btn {
    width: 8rem;
    background-color: $secondaryoffWhite;

    @include tablet {
        width: 10rem;
    }
}